import { normalizeBlockContentNodes } from '@blocks-helpers';
import { graphql } from 'gatsby';
import { Container, merge } from 'theme-ui';
import ContentText from '@solid-ui-components/ContentText';
import ConvertTextToComponent from '../../../../site/content/components/ConvertTextToComponent';
import Layout from '@solid-ui-layout/Layout';
import Seo from '@solid-ui-components/Seo';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import flowUITheme from '@elegantstack/flow-ui-theme/src/theme';
import theme from '../new-landing/_theme';

const Terms = (props) => {
  const allBlockContent = props.data.allBlockContent;
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  return (
    <Layout theme={merge(theme, { ...flowUITheme, colors: {} })} {...props}>
      <Seo title={content['terms']?.title?.text} />
      <Header content={content['header']} />
      <Container sx={{ maxWidth: '1080px' }} mt={6}>
        <ContentText
          sx={{ textAlign: 'center', textTransform: 'uppercase' }}
          content={{ text: content['terms']?.title?.text }}
          variant='h2'
        />
        <ConvertTextToComponent
          content={content['terms'].description.detailedTexts}
          marginX='4'
          marginY='2'
        />
      </Container>
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  );
};
export const query = graphql`
  query siteTermsBlockContent($blocksPaths: [String]!) {
    allBlockContent: allBlockContent(
      filter: {
        page: { in: $blocksPaths }
        identifier: { in: ["footer", "header", "terms"] }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default Terms;
